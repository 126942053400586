const Text = {
  baseStyle: {
    fontWeight: "600",
    fontFamily:"'IBM Plex Mono', monospace;",
  },
  variants: {
    "Label": {
      color: 'brandYellow.300',
    }
    
  }
}

export default Text
