const Link = {
    
  baseStyle: {
    _hover: {
      fontWeight: '800'
    }
  },

}

export default Link