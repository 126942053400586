const Container = {
  variants: {
    "Black": {
      backgroundColor: "#000000",
      color: "#ffffff"
    },
    "Grey": {
      backgroundColor: "#f6f6f6",
      color: "#4a4a4a"
    },
    "Text": {
      fontWeight: "600",
      fontFamily:"'IBM Plex Mono', monospace;",
    },
    "Error": {
      fontWeight: "600",
      paddingLeft: 0,
      fontFamily:"'IBM Plex Mono', monospace;",
    },
    "Footer": {
      color: "#653088"
    }
  }
}

export default Container
