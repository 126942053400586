const Button = {
    // The styles all button have in common
    baseStyle: {
      fontWeight: "bold",
      fontFamily: "Project44Lite",
      textTransform: "uppercase",
      // borderRadius: '35px'
    },
    // Two sizes: sm and md
    sizes: {
      sm: {
        fontSize: "12px",
        padding: "16px",
      },
      md: {
        fontSize: "16px",
        padding: "24px",
      },
    },
    // Two variants: outline and solid
    // variants: {
    //   onBlack: {
    //     // border: "2px solid",
    //     // color: "black",
    //     fontWeight: "500",
    //     // borderColor: "white",
    //     // borderRadius: "0px",
    //     // isFullWidth: false,
    //     // _hover: {
    //     //     bg: "green.500" 
    //     // }
    //   },
    // },
    // // The default size and variant values
    // defaultProps: {
    //   // size: "md",
    //   variant: "onBlack",
    // },
  }

  export default Button