import React from "react"
import { Global } from '@emotion/react'
import { ChakraProvider, CSSReset, extendTheme } from "@chakra-ui/react"
import myTheme from "./src/styles/theme"
import fonts from "./src/styles/fonts"
import { Helmet } from "react-helmet";

const gtag = process.env.GATSBY_GTAG_KEY
const theme = extendTheme(myTheme)

export default ({ element }) => {
    var gtagURL = "https://www.googletagmanager.com/gtag/js?id=" + gtag 
    return (
        <>
            <Helmet>
                <script defer src={gtagURL}></script>
                <script defer>
                    {`
                window.dataLayer = window.dataLayer || [];
          function gtag(){dataLayer.push(arguments);}
          gtag('js', new Date());
          gtag('config', 'G-4BH83P7ZH0');
        `}
                </script>
            </Helmet>
            <ChakraProvider theme={theme}>
                <CSSReset />
                <Global styles={fonts} />
                {element}
            </ChakraProvider>
        </>
    )
}
