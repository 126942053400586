import Button from './components/button'
import Text from './components/text'
import Heading from './components/heading'
import Container from './components/container'
import Link from './components/link'

export default {
  breakpoints: ["30em", "48em", "62em", "80em"],
  styles: {
    global: {
      html: {
        height: '100%',
        margin: '0',
      },
      body: {
        '-webkit-animation': 'fadein 1.5s',
        background: '#080335',
        height: '100%',
        margin: '0',
        color: "brandWhite.50",
        backgroundColor: '#080335',
      },
      strong: {
        fontWeight: 'bold',
        backgroundColor: 'brandYellow.300',
        color: 'black'
      }
    },
  },
  colors: {
    brandWhite:
    {
      50: '#f2f2f2',
      100: '#d9d9d9',
      200: '#bfbfbf',
      300: '#a6a6a6',
      400: '#8c8c8c',
      500: '#737373',
      600: '#595959',
      700: '#404040',
      800: '#262626',
      900: '#0d0d0d',
    },
    brandYellow: {
      50: '#ffffda',
      100: '#ffffad',
      200: '#fffe7d',
      300: '#fffe4b',
      400: '#fffe1a',
      500: '#e6e400',
      600: '#b3b200',
      700: '#807f00',
      800: '#4d4c00',
      900: '#1b1900',
    },
    brandRed: 
    {
      50: '#ffe4e4',
      100: '#fcb9b9',
      200: '#f38d8d',
      300: '#ec5f5f',
      400: '#e63333',
      500: '#cc1919',
      600: '#a01213',
      700: '#720b0d',
      800: '#470405',
      900: '#1f0000',
    }
  },
  fonts: {
    body: "'IBM Plex Mono', monospace",
    heading: "Project44Lite"
  },
  components: {
    Button,
    Link,
    Container,
    Text,
    Heading
  },
}
