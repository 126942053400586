const Heading = {
  baseStyle: {
    fontWeight: 500,
    fontFamily: "'IBM Plex Sans', sans-serif",
    // textTransform: "uppercase",
    // letterSpacing: ".12em",
    color: 'bandYellow.500'
  },
  variants: {
    "SectionTitle": {
      color: 'brandYellow.500',
      fontWeight: 'bold'
    },
    "Title": {
      fontWeight: 500,
      textShadow: "1px 1px 5px #d1c61c, 1px 1px 5px #d1c61c",
      // textTransform: "uppercase",
      letterSpacing: ".1em",
      fontFamily: "Project44Lite",
      color: 'brandYellow.500'
    }
  }

}

export default Heading
