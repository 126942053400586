import Amplify from "aws-amplify";
import Provider from "./provider"

// Amplify.Logger.LOG_LEVEL = 'DEBUG'; 
Amplify.configure({
  Auth: {
    mandatorySignIn:  false,
    region: 'eu-west-1',
    identityPoolRegion: 'eu-west-1',
    userPoolId: process.env.GATSBY_USER_POOL_ID,
    identityPoolId: process.env.GATSBY_IDENTITY_POOL_ID,
    userPoolWebClientId: process.env.GATSBY_WEB_CLIENT_ID,
  },
  API: {
    endpoints: [ 
      {
        name: "api",
        region: 'eu-west-1',
        endpoint: process.env.GATSBY_API_ENDPOINT
      }
    ]
  }
})



export const wrapRootElement = Provider

export const onClientEntry = async () => {  
    if (typeof window.IntersectionObserver === `undefined`) {
        await import(`intersection-observer`)
    }
  }



